import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../../components'

import 'animate.css/animate.min.css'
import { sanitizeContent } from '../../utilities/sanitizeContent'

export default props => {
  const { data: { pagesJson } } = props

  const page = sanitizeContent(pagesJson)

  return (
        <Layout
        title={page && page.title}

        description={page && page.description}

        metaTags={page && page.metaTags}

        blocks={page && page.blocks}

        seo_image={page && page.seo_image} />
  )
}

export const pageQuery = graphql`
query ($slug: String!) {
  pagesJson(slug: {eq: $slug }) {
    title
    description
    slug
    siteSearch
    seo_image
    metaTags {
      name
      content
    }
    blocks {
      aboutTopImage
      aboutBottomImage
      aboutTopBody
      aboutBottomTitle
      aboutBottomBody
      aboutBottomButtons {
        sharedTextButton {
          style
          label
          type
          page
          form
          url
          file
          phone
        }
      }
      appStoreIcons {
        icon
        url
      }
      arrowLeft
      arrowRight
      arrowUp
      arrowDown
      background
      bandwidthEstimatorButtonText
      bandwidthEstimatorFooterSubtitle
      bandwidthEstimatorFooterTitle
      bandwidthEstimatorIcon
      bandwidthEstimatorSubtitle
      bandwidthEstimatorSliderOneText
      bandwidthEstimatorSliderOneIcon
      bandwidthEstimatorSliderTwoText
      bandwidthEstimatorSliderTwoIcon
      bandwidthEstimatorText
      bandwidthEstimatorTitle
      bandwidthEstimatorYesNoText
      bandwidthEstimatorYesNoIcon
      bannerSubtitle
      blockTitle
      blogFeaturedArticle
      body
      businessInternetSpeedsButtons {
        sharedTextButton {
          label
          page
          style
          type
          url
          file
          phone
        }
      }
      businessInternetSpeedsInformation
      businessInternetSpeedsFooterMessage
      businessInternetSpeedsSpeeds {
        downloadSpeed
        uploadSpeed
      }
      businessInternetSpeedsText
      buttons {
        sharedTextButton {
          style
          label
          type
          page
          form
          url
          file
          phone
        }
        buttonFooter
      }
      categories
      careers {
        careersTitle
        careersLocation
        careersUrl
      }
      cloudCommunicationsImage
      cloudCommunicationsIcons {
        icon
        label
      }
      cloudCommunicationsText
      cloudPlansSubHeading
      cloudPlansDescription
      cloudPlansOptions {
        option
        plans
      }
      cloudPlansPlans {
        title
        price
        priceSuffix
        sharedTextButton {
          label
          page
          style
          type
          form
          url
          file
          phone
        }
      }
      cloudPlansFooterText
      cloudPlansIcon
      contactIcons {
        icon
        text
        path
      }
      contactTicketStatusText
      contentTypeTeamMembersManagement {
        fullName
        image
        firstName
        lastName
        title
        contactInfo {
          type
          phone
          url
          email
        }
      }
      coverageFooterText
      description
      devices {
        devicesImage
        devicesModel
        deviceBluetooth
        deviceWifi
        deviceUsb
        deviceSpecs
        sharedTextButton {
          style
          label
          type
          article
          page
          url
          file
          phone
        }
        deviceBrandLogo
      }
      disclaimer
      downloadIcon
      featureColumnsHeading
      featureColumnsFooter
      featureColumnsColumns {
        title
        disclaimer
        icon
        highlight
        showButton
        sharedTextButton {
          label
          page
          style
          type
          form
          url
          phone
          file
        }
        popularTab
        description
        highlightPrefix
        highlightSuffix
        footer
      }
      featureColumnsShowButton
      featureColumnsSubheading
      featureTabsTabs {
        icon
        title
        text
      }
      featureTabsButtons {
        sharedTextButton {
          label
          page
          style
          type
          form
          url
          file
          phone
        }
      }
      featureTabsImage
      feedback {
        feedbackFirstName
        feedbackLastName
        feedbackMessage
        feedbackFeedbackType
        company
        feedbackLocation
      }
      feedbackFeedbackInvitation
      feedbackSubtitle
      form
      formId
      footerText
      footerSubtext
      heading
      heroBody
      heroImage
      heroLogo
      heroShowPageTitle
      heroSmallTitle
      heroTitleSize
      heroButtons {
        sharedTextButton {
          label
          page
          style
          type
          form
          url
          file
          phone
        }
      }
      image
      image_caption
      image_width
      image_position
      list_of_icons{
        icon
        icon_heading
        icon_body_text
      }
      LoR
      label
      main_image
      moreVideosLabel
      negative_top_margin
      otherZips {
        text
        callToActions {
          url
          page
          form
          text
          linkType
          phone
        }
      }
      outOfStateZips {
        text
        callToActions {
          url
          page
          form
          text
          linkType
          phone
        }
      }
      overlap
      portalId
      privacy
      promotionsPromotions {
        image
        image_width
        image_position
        title
        buttons {
          sharedTextButton {
            style
            label
            type
            page
            form
            url
            file
            phone
          }
        }
        body
      }
      residentialInternetBody
      residentialInternetImage
      residentialInternetButtons {
        sharedTextButton {
          style
          label
          type
          page
          form
          url
          phone
        }
      }
      residentialVoiceImage
      residentialVoiceBody
      sharedTextButton {
        style
        label
        type
        page
        form
        url
        file
        phone
      }
      sidebarBlocks {
        type
        label
        url
        documentsHeading
        documents {
          document
          label
        }
        page
        article
        heading
        bodyTop
        bodyBottom
        logos {
          logo
        }
        privacy
        sharedTextButton {
          label
          page
          style
          type
          form
          url
          file
          phone
        }
        tariffsHeading
        tariffItems {
          tariffItem
        }
        videos {
          video
        }
      }
      socialLinks {
        icon
        url
        label
      }
      solutionsButtons {
        sharedTextButton {
          label
          page
          style
          type
          form
          url
          file
          phone
        }
      }
      solutionsCategories {
        body
        icons {
          icon
          label
          page
          type
          url
        }
        name
      }
      speedsCategories {
        defaultDisclaimer
        locations {
          name
          download
          upload
          disclaimer
        },
        sharedTextButton {
          style
          label
          type
          page
          form
          url
          file
          phone
        }
        title
      }
      subHeading
      supportCarouselBody
      supportCarouselFooterText
      supportCarouselSubtitle
      supportSearchLabel
      supportFooterText
      supportLinksTitle
      supportFaqSectionTitle
      supportFaqSectionSubtitle
      supportFaqCategories {
        subcategory
      }
      supportLinks {
        article
        page
      }
      supportVideoCategories {
        supportVideoCategory
      }
      supportIcons {
        icon {
          text
          icon
          linkTo
        }
      }
      teamTeam
      teamSubtitle
      techProtectBottomText
      techProtectFeatureGrid {
        icon
        label
      }
      techProtectFooterText
      testimonialsType
      text
      timelineEvents {
        year
        image
        description
      }
      type
      uploadIcon
      videoUrl
      video_url
      videos {
        video
      }
      waveImage
      whyFiberImage
      whyFiberTabs {
        icon
        title
        text
      }
      whyFiberButtons {
        sharedTextButton {
          label
          page
          style
          type
          form
          url
          file
          phone
        }
      }
      zipCodes {
        text
        callToActions {
          url
          page
          form
          text
          linkType
          phone
        }
        zip
      }
    }
  }
}
`